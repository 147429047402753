import './styles.scss';
import { connect } from 'react-redux';

import { closeForms } from 'store/actions';

const Notification = ({ history, closeForms }) => {
    setTimeout(() => { closeForms() }, 2000)
    let { player, sum, result } = history[history.length - 1];

    if (result === 'point-point') result = 'lose'

        if (result.includes('point-')) {
            result = result.replace('point-', '') + ' in point round';
        };

    return (
        <div className='notification-container'>
            <span className='notification'>{`${player} rolled ${sum}, its a ${result}`}</span>
        </div>
    )
}


const mapStateToProps = (state) => ({
    history: state.history,
})

const mapDispatchToProps = ({
    closeForms
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification);