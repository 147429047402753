import './styles.scss';
import { connect } from 'react-redux';
import { setDices, showResetGame } from 'store/actions';

import ResetGameForm from '../../Forms/ResetGameForm';
import DebugForm from '../../Forms/DebugForm';
import Notification from '../../Forms/Notification';
import Congratulations from '../../Forms/Congratulations';

import Dices from '../Dices';

const Controls = ({ status, name, showResetGame, show_reset, show_status }) => {
    const isDebugMode = JSON.parse(sessionStorage.getItem('is-debug'));

    return (
        <div className="dices-container flex-item main-app-part">
            <h2 className='players-name-turn'>{name}’s turn</h2>
            <h1>{
                status === 'game-win' ? 'You won the round' :
                    status === 'round-win' ? 'You won the round' :
                        'Roll dices'
            }</h1>

            <Dices />

            <button className="controls-button cancel-button" onClick={() => showResetGame()}>End game</button>
            {show_reset ? <ResetGameForm /> : ''}
            {isDebugMode ? <DebugForm /> : ''}

            {show_status ? <Notification /> : ''}

            {status === 'end game' ? <Congratulations /> : ''}

        </div>
    )
}

const mapStateToProps = (state) => ({
    name: state[state.current_player],
    player: state.current_player,
    status: state.status,
    dices: state.dices,
    show_reset: state.show_reset,
    show_status: state.show_status,
})

const mapDispatchToProps = ({
    setDices,
    showResetGame,
})

export default connect(mapStateToProps, mapDispatchToProps)(Controls);