export const setNames = (payload) => ({
    type: 'SET_PLAYERS_NAMES',
    payload
});

export const setDices = (payload) => ({
    type: 'SET_DICES',
    payload
});

export const setDebug = (payload) => ({
    type: 'SET_DEBUG',
    payload
});

export const resetGame = () => ({
    type: 'RESET_GAME',
});

export const showResetGame = () => ({
    type: 'SHOW_RESET_GAME',
});

export const closeForms = () => ({
    type: 'CLOSE_FORMS',
});