import './styles.scss';
import { connect } from 'react-redux';

import { useEffect } from 'react';

const ResultTable = ({ player_1, player_2, history, points_player_1, points_player_2 }) => {
    useEffect(() => {
        let tbody = document.querySelector('.table-container');
        tbody.scrollTop = tbody.scrollHeight + 1000;
    }, [history])

    return (
        <div className="table-container flex-item main-app-part">
            <div className='table-header'>
                <div>{player_1} <span className="player-points">{points_player_1}</span></div>
                <div>{player_2} <span className="player-points">{points_player_2}</span></div>
            </div>
            <table className='table-content'>
                <tbody className='table-body'>
                    {
                        history.map((el, index) => (
                            <tr key={"round" + index} className={`result-row ` + el.result.replaceAll('point-', '')} >
                                <td className='row-number'>{index + 1}</td>
                                <td className='player-name'>{el.player}</td>
                                <td className='player-sum'>{el.sum}</td>
                                <td>
                                    {el.result === 'win' ? el.player + ' won the round' : ''}
                                    {el.result === 'lose' ? el.player + ' lose the round' : ''}
                                    {el.result === 'point' ? el.player + ' rolled point' : ''}
                                    {el.result === 'point-win' ? el.player + ' won in point' : ''}
                                    {el.result === 'point-lose' ? el.player + ' lose in point' : ''}
                                    {el.result === 'point-point' ? el.player + ' got one more try' : ''}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div >
    )
}

const mapStateToProps = (state) => ({
    player_1: state.player_1,
    player_2: state.player_2,
    history: state.history,
    points_player_1: state.points_player_1,
    points_player_2: state.points_player_2,
})

export default connect(mapStateToProps, null)(ResultTable);