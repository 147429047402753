import Craps from './components/Craps';
import './App.css';

function App() {
  return (
    <Craps/>
  );
}

export default App;
