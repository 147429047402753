import './styles.scss';
import { Formik, Field, Form } from 'formik';
import { connect } from 'react-redux';
import { setDebug } from 'store/actions';

const DebugForm = ({ setDebug, }) => {

    const initualValues = {
        dice_1: '',
        dice_2: '',
    }

    const submitHandler = (values) => {
        const dices = [+values.dice_1, +values.dice_2];
        if (dices[0] && dices[1]) { setDebug(dices) }
    }

    return (
            <Formik
                initialValues={initualValues}
                onSubmit={submitHandler}
            >
                {form => (<Form className='set-dices-form'>
                    <div className='controls-container'>
                        <label htmlFor="dice_1">Dice 1</label>
                        <Field
                            className="dices-input"
                            id="dice_1"
                            name="dice_1"
                            placeholder="Number"
                        />
                    </div>

                    <div className='controls-container'>
                        <label htmlFor="dice_2">Dice 2</label>
                        <Field
                            className="dices-input"
                            id="dice_2"
                            name="dice_2"
                            placeholder="Number"
                        />
                    </div>

                    <button type="submit" className='controls-button submit-button'>Set dices for next roll</button>
                </Form>
                )}
            </Formik>
    )
}

const mapDispatchToProps = {
    setDebug
}

export default connect(null, mapDispatchToProps)(DebugForm);