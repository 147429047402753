import { SET_PLAYERS_NAMES, SET_DICES, RESET_GAME, SET_DEBUG, SHOW_RESET_GAME, CLOSE_FORMS } from './types';

const local = JSON.parse(localStorage.getItem('dices-app'));

if (local) {

}

const freshApp = {
    player_1: "",
    player_2: "",
    points_player_1: 0,
    points_player_2: 0,
    dices: [0, 0],
    debug_dices: [1, 1],
    status: null,
    current_player: 'player_1',
    is_point: false,
    history: [],
    show_reset: false,
    show_status: false,
};

const initial = local || { ...freshApp };

const reducer = (state = initial, action) => {
    const payload = action.payload;

    switch (action.type) {

        case SET_PLAYERS_NAMES: {
            const newState = { ...state };
            newState.player_1 = payload.player_1.charAt(0).toUpperCase() + payload.player_1.slice(1);
            newState.player_2 = payload.player_2.charAt(0).toUpperCase() + payload.player_2.slice(1);;
            return { ...newState };
        }

        case SET_DICES: {
            const newState = { ...state };
            newState.dices = payload;
            newState.history = [...state.history];

            const sum = newState.dices[0] + newState.dices[1];

            const current_player = newState.current_player;

            let status;

            if (!newState.is_point) {

                if (sum === 7 || sum === 11) {
                    status = 'win';
                    newState['points_' + current_player] += 1;
                    current_player === 'player_1' ? newState.current_player = 'player_2' : newState.current_player = 'player_1';
                } else if (sum === 8 || sum === 12 || sum === 6) {
                    status = 'lose';
                    current_player === 'player_1' ? newState.current_player = 'player_2' : newState.current_player = 'player_1';
                } else {
                    status = 'point';
                    newState.is_point = true;
                }

            } else if (newState.is_point) {

                if (sum === 7) {

                    status = 'point-lose';
                    current_player === 'player_1' ? newState.current_player = 'player_2' : newState.current_player = 'player_1';
                    newState.is_point = false;

                } else if (sum === 1 || sum === 4 || sum === 5 || sum === 6 || sum === 9 || sum === 10) {

                    status = 'point-win';
                    newState['points_' + current_player] += 1;
                    current_player === 'player_1' ? newState.current_player = 'player_2' : newState.current_player = 'player_1';
                    newState.is_point = false;

                } else if (sum === 2 || sum === 3 || sum === 8 || sum === 12 || sum === 11) {
                    status = 'point-point';
                } else {
                    console.log('something gone wrong')
                }
            }

            if (newState.points_player_1 >= 10 || newState.points_player_2 >= 10) newState.status = 'end game'

            newState.history.push({
                player: newState[current_player],
                sum,
                result: status,
            })

            newState.show_status = true;

            return { ...newState };
        }

        case RESET_GAME: {
            const newState = { ...freshApp };
            return { ...newState };
        }

        case SET_DEBUG: {
            const newState = { ...state };
            newState.debug_dices = payload;
            return { ...newState };
        }

        case SHOW_RESET_GAME: {
            const newState = { ...state };
            newState.show_reset = true;
            return { ...newState };
        }

        case CLOSE_FORMS: {
            const newState = { ...state };
            newState.show_reset = false;
            newState.show_status = false;
            return { ...newState };
        }

        default: {
            return { ...state }
        }
    }
}

export { reducer };