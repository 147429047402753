import './styles.scss';
import Logo from 'assets/vectors/logo.svg'

const CompanyLogo = () => {

    return (
        <>
            <hr style={{ size: 1, width: "100%" }} />
            <img src={Logo} alt="Hillel IT School"/>
        </>
    )
}

export default CompanyLogo;