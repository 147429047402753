import './styles.scss';

import AppTitle from './AppTitle';
import { connect } from 'react-redux';

import ResultsTable from './Frames/ResultsTable';
import Dices from './Frames/Controls';
import PlayersNamesForm from './Forms/PlayersNameForm';

import { isPlayersSet } from 'store/selectors';

// import {  } from 'store/actions';

const MainApp = ({ isPlayersSet }) => {

    return (
        <div className='app-main-frame'>
            <div className="app-head flex-container">
                <AppTitle
                    text={'🎲 Craps game'}
                />
            </div>
            <div className="app-content flex-container">
                {
                    isPlayersSet ?
                        <>
                            <ResultsTable />
                            <Dices />
                        </>
                        :
                        <PlayersNamesForm />
                }
            </div>
        </div>
    )
}

const mapStateToPrors = state => ({
    isPlayersSet: isPlayersSet(state)
});

const mapDispatchToProps = {

}

export default connect(mapStateToPrors, mapDispatchToProps)(MainApp);