import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'

import { reducer } from './reducer';

const store = createStore(reducer, composeWithDevTools())


store.subscribe(() => {
    localStorage.setItem('dices-app', JSON.stringify(store.getState()))
})

export default store;