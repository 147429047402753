import './styles.scss';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';

import { resetGame, closeForms } from 'store/actions';

const ResetGameForm = ({ resetGame, closeForms }) => {
    return (<div className='custom-form-outlet full-page-outlet'>
        <div className='shade-background'>
        </div>
        <div className="my-form">
            <div className="form-heading">
                <h4 className='form-title'>Reset a game?</h4>
                <button className="close-button" onClick={closeForms}></button>
            </div>
            <p className='form-content'>Are you sure you want to reset game?</p>

            <Formik
                initialValues={{
                    number: '',
                }}

                onSubmit={async () => {
                    resetGame();
                }}
            >
                <Form className='reset-game-form'>
                    <button type="submit" className='controls-button submit-button'>Yes, reset the game</button>
                </Form>
            </Formik>
        </div>

    </div>)

}

const mapDispatchToProps = {
    resetGame,
    closeForms,
}

export default connect(null, mapDispatchToProps)(ResetGameForm);