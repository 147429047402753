import './styles.scss';
import CompanyLogo from 'components/shared/CompanyLogo';

const Instruction = () => {


    return (
        <div
            className='app-instruction'
        >
            <h2 className="requirements-title">Requirements for testing</h2>
            <div className='scrollable'>

                <p className="requirements-text">
                The player rolls two dice and calculates the sum of points scored. If it is 7 or 11, he won, if it is 2, 8, or 12, he lost. If another amount fell out - a special round named “point”. The player rolls the dice until a 7 comes out - this means a loss or until a point falls out again - the player wins, another number - continues "point" round. The winner is who first gets 10 wins.
                </p>
            </div>
            <CompanyLogo />
        </div>
    )
}

export default Instruction;