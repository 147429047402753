import './styles.scss';
import { connect } from 'react-redux';
import { setDices } from 'store/actions';

const Dices = ({ debug_dices, setDices }) => {
    const isDebugMode = JSON.parse(sessionStorage.getItem('is-debug'));
    var cubes = document.getElementsByClassName('cube');

    var min = 10;
    var max = 25;

    const roll = function () {
        let result = []

        for (let [index, cube] of Array.from(cubes).entries()) {
            let xRand;
            let yRand;

            if (isDebugMode) {
                switch (debug_dices[index]) {
                    case 1: {
                        xRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        yRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        break
                    }
                    case 2: {
                        xRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        yRand = (getRandom(3, 7) / 90 * 4 + 2) * 90;
                        break
                    }
                    case 3: {
                        xRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        yRand = (getRandom(3, 7) / 90 * 4 + 3) * 90;
                        break
                    }
                    case 4: {
                        xRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        yRand = (getRandom(3, 7) / 90 * 4 + 1) * 90;
                        break
                    }
                    case 5: {
                        xRand = (getRandom(3, 7) / 90 * 4 + 3) * 90;
                        yRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        break
                    }
                    case 6: {
                        xRand = (getRandom(3, 7) / 90 * 4 + 1) * 90;
                        yRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        break
                    }
                    default: {
                        xRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                        yRand = (getRandom(3, 7) / 90 * 4 + 4) * 90;
                    }
                }

            } else {
                xRand = getRandom(max, min);
                yRand = getRandom(max, min);
            }

            cube.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';
            cube.style.transform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';

            result.push(getResult(xRand, yRand));
        }

        setTimeout(() => { setDices(result) }, 2000);
    }

    function getRandom(max, min) {
        return (Math.floor(Math.random() * (max - min)) + min) * 90;
    }

    // modulo not giving negative results - see https://stackoverflow.com/q/4467539/1336843
    function mod(n, m) {
        return ((n % m) + m) % m;
    }

    function getResult(rotX, rotY) {

        let countX = mod(rotX / 90, 4);

        if (countX === 1) {
            // Bottom face
            return 6;
        }
        if (countX === 3) {
            // Top face
            return 5;
        }
        // We add countX here to correctly offset in case it is a 180 degrees rotation
        // It can be 0 (no rotation) or 2 (180 degrees)
        let countY = mod(rotY / 90 + countX, 4);
        // Faces order
        return [1, 4, 2, 3][countY];

    }

    return (
        <>
            <div className="dices">
                <div className="container">
                    <div className="cube">
                        <div className="face front">
                            <div className="dot dot1"></div>
                        </div>

                        <div className="face back">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                        </div>

                        <div className="face right">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                        </div>

                        <div className="face left">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                            <div className="dot dot4"></div>
                        </div>

                        <div className="face top">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                            <div className="dot dot4"></div>
                            <div className="dot dot5"></div>
                        </div>

                        <div className="face bottom">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                            <div className="dot dot4"></div>
                            <div className="dot dot5"></div>
                            <div className="dot dot6"></div>
                        </div>

                    </div>
                </div>
                <div className="container">
                    <div className="cube">
                        <div className="face front">
                            <div className="dot dot1"></div>
                        </div>

                        <div className="face back">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                        </div>

                        <div className="face right">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                        </div>

                        <div className="face left">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                            <div className="dot dot4"></div>
                        </div>

                        <div className="face top">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                            <div className="dot dot4"></div>
                            <div className="dot dot5"></div>
                        </div>

                        <div className="face bottom">
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                            <div className="dot dot4"></div>
                            <div className="dot dot5"></div>
                            <div className="dot dot6"></div>
                        </div>

                    </div>
                </div>
            </div>

            <button className="controls-button" onClick={roll}>Roll</button>
        </>
    )
}

const mapStateToProps = (state) => ({
    debug_dices: state.debug_dices
});

const mapDispatchToProps = ({
    setDices,
})

export default connect(mapStateToProps, mapDispatchToProps)(Dices);