import * as Yup from 'yup';

export const playersNames = Yup.object({
    player_1: Yup.string()
        .required('Name is required')
        .matches(/^([a-z]|\s)+$/i, 'Only a-z symbols')
        .min(1, 'Name should be 2 or more symbols length')
        .max(20, 'Name should be 20 or less symbols length'),
    player_2: Yup.string()
        .required('Name is required')
        .matches(/^([a-z]|\s)+$/i, 'Only a-z symbols')
        .min(1, 'Name should be 2 or more symbols length')
        .max(20, 'Name should be 20 or less symbols length'),
});