import './styles.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { connect } from 'react-redux';

import { playersNames } from '../../../../shared/myValidation'

import MyErrorMessage from 'components/shared/MyErrorMessage';

import { setNames } from 'store/actions';

const PlayersNamesForm = ({ setNames, }) => {
    const formValidation = playersNames;
    const initualValues = {
        player_1: '',
        player_2: '',
    }

    const submitHandler = async (values) => {
        setNames(values);
    }

    return (<div className='custom-form-outlet'>
        <div className="my-form">
            <Formik
                initialValues={initualValues}
                onSubmit={submitHandler}
                validationSchema={formValidation}
            >
                {form => (<Form className='set-players-form'>
                    <div className='controls-container'>
                        <label htmlFor="player_1">Player 1</label>
                        <Field
                            className="controls-input"
                            id="player_1"
                            name="player_1"
                            placeholder="Name"
                        />
                        <ErrorMessage name="player_1" component={err => <MyErrorMessage text={err.children} />} />
                    </div>

                    <div className='controls-container'>
                        <label htmlFor="player_2">Player 2</label>
                        <Field
                            className="controls-input"
                            id="player_2"
                            name="player_2"
                            placeholder="Name"
                        />
                        <ErrorMessage name="player_2" component={err => <MyErrorMessage text={err.children} />} />
                    </div>

                    <button type="submit" className='controls-button submit-button' disabled={!form.isValid || !form.dirty}>Start game</button>
                </Form>
                )}
            </Formik>
        </div>
    </div>)

}

const mapDispatchToProps = {
    setNames
}

export default connect(null, mapDispatchToProps)(PlayersNamesForm);