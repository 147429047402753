import './styles.scss';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';

import { resetGame } from 'store/actions';

const Congratulation = ({ points, player_1, player_2, resetGame }) => {

    return (<div className='custom-form-outlet full-page-outlet'>
        <div className='shade-background'>
        </div>
        <div className="my-form">
            <div className="form-heading">
                <h4 className='form-title'>Congratulations 🥳</h4>
            </div>
            <p className='form-content'>Player {points.player_1 >= 10 ? player_1 : player_2} won the game!</p>

            <Formik
                initialValues={{
                    number: '',
                }}

                onSubmit={async () => {
                    resetGame();
                }}
            >
                <Form className='reset-game-form'>
                    <button type="submit" className='controls-button submit-button'>Start new game</button>
                </Form>
            </Formik>
        </div>

    </div>)
}


const mapStateToProps = (state) => ({
    history: state.history,
    points: {
        player_1: state.points_player_1,
        player_2: state.points_player_2,
    },
    player_1: state.player_1,
    player_2: state.player_2,
})

const mapDispatchToProps = ({
    resetGame
})

export default connect(mapStateToProps, mapDispatchToProps)(Congratulation);